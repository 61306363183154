import * as arabic from './arabic.js';
import * as german from './german.js';

export default function useLessonData(language) {
  switch(language) {
    case 'arabic':
      return arabic
    case 'german':
      return german;
    default:
      throw new Error(`No lessons found for language "${language}"`);
  }

}
