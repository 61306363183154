import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import './Quiz.scss';
import AnswerChoice from './AnswerChoice';
import AnswerNumber from './AnswerNumber';
import QuestionNumber from './QuestionNumber';
import QuestionAudio from './QuestionAudio';
import QuestionText from './QuestionText';

import useLessonData from '../lessons/hooks.js';

import { useGenerateQuestions } from './hooks';
import useDb from '../shared/useDb';

function Quiz(props) {
  const { language, lessonId, exerciseId } = useParams();
  const choiceCount = 4;
  const history = useHistory();
  const lessonData = useLessonData(language);
  const lessons = lessonData.lessons;
  const lesson = lessons[lessonId];
  const exercise = lesson.exercises[exerciseId];
  let db = useDb();

  const [questions] = useState(useGenerateQuestions(lesson, exercise.questionOrder));
  const [questionIndex, setQuestionIndex] = useState(0);
  let [correctlyAnswered, setCorrectlyAnswered] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if(correctlyAnswered.length === questions.length) {
      const correctAnswers = correctlyAnswered.filter((i) => i === true).length;
      const wrongAnswers = correctlyAnswered.filter((i) => i === false).length;

      db.insertOrUpdate('progress',
        { exerciseId: exercise.id },
        {
          exerciseId: exercise.id,
          correctAnswers: correctAnswers,
          wrongAnswers: wrongAnswers,
          lastCompleted: Date.now()
        }
      );
      db.commit();

      history.push(`/${language}/completion/${exercise.id}`);
    }
  }, [correctlyAnswered, language, questions, history, exercise, db])


  function setNextQuestion() {
    if(questionIndex < questions.length -1) {
      setQuestionIndex(questionIndex + 1);
    } else {
    }
  }

  function handleCorrectAnswer(firstAnswerCorrect) {
    setCorrectlyAnswered(prev => {
      return prev.concat(firstAnswerCorrect)});
    setNextQuestion();
  }

  function prepareText(text) {
    if(lessonData.getNumberWord) {
      return lessonData.getNumberWord(text);
    } else {
      return text;
    }
  }

  function handleClose() {
    history.push(`/${language}`);
  }

  return (
    <div className="quiz">
      <div className="top-bar">
        <button className="close secondary" onClick={handleClose}>X</button>
      </div>
      <>
        <div className="question">
          { exercise.q.type === 'number' &&
          <>
            <h1>What number do you see?</h1>
            <QuestionNumber
              question={questions[questionIndex]}
              numberSystem={exercise.q.numberSystem}
            />
          </>
          }

          { exercise.q.type === 'audio' &&
            <>
              <h1>What number do you hear?</h1>
              <QuestionAudio question={questions[questionIndex]}/>
            </>
          }

          { exercise.q.type === 'text' &&
            <>
              <h1>What number do you read?</h1>
              <QuestionText question={prepareText(questions[questionIndex])}/>
            </>
          }



          { exercise.q.type === 'combi' &&
            <div className="combi-question">
              <h1>What number do you see and hear?</h1>
              <QuestionNumber
                question={questions[questionIndex]}
                numberSystem={exercise.q.numberSystem}/>
              <QuestionAudio question={questions[questionIndex]}/>
            </div>
          }

        </div>

        <div>
          { exercise.a.type === 'choice' &&
          <AnswerChoice
            question={questions[questionIndex]}
            min={lesson.min}
            max={lesson.max}
            choiceCount={exercise.choiceCount || choiceCount}
            stepSize={lesson.stepSize}
            handleCorrectAnswer={handleCorrectAnswer}
            numberSystem={exercise.a.numberSystem}
          />
          }
          { exercise.a.type === 'number' && <AnswerNumber
            question={questions[questionIndex]}
            handleCorrectAnswer={handleCorrectAnswer}
          /> }
        </div>
      </>

    </div>
  )
}

export default Quiz;
