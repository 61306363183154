import generateNumberWord from '../shared/numberWords';

const meta = {
  title: 'Learn German Numbers'
};

const lessons = [
  {
    id: 'ef0a1075-80da-4b3d-8459-a4417813fbc9',
    min: 0,
    max: 9,
    exercises: [
      {
        id: '74287e2d-0201-49ae-a7bd-34613f7f6692',
        title: 'Intro',
        q: { type: 'audio' },
        a: { type: 'choice' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: '15981578-4ca2-4fd5-ac99-9cbf26d700ef',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' }
      },
      {
        id: 'c6268646-f3e6-4ff1-9006-027727d26dcc',
        title: 'Write',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      {
        id: '3864fa6f-38ab-4427-922f-5e1370efe290',
        title: 'Read',
        q: { type: 'text' },
        a: { type: 'choice' },
      }
    ]
  },
  {
    id: '8f4d285b-658b-4813-912d-ca1614577a00',
    min: 10,
    max: 20,
    exercises: [
      {
        id: '0edc191b-0959-41c8-b4d2-9520f355b148',
        title: 'Intro',
        q: { type: 'audio' },
        a: { type: 'choice' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: '93e9648a-82c7-432e-a0b6-0489a5f0a908',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' }
      },
      {
        id: '5a36565a-395e-4c67-a676-786092bfd447',
        title: 'Write',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      {
        id: '9258e9c0-894d-4f53-8dc9-43a24265f0d3',
        title: 'Read',
        q: { type: 'text' },
        a: { type: 'choice' },
      }
    ]
  },
  {
    id: '63eb8d7b-e51a-4911-b482-faa96cf0cf7d',
    min: 20,
    max: 30,
    exercises: [
      {
        id: 'eb5ce536-0438-4f75-8205-e64e1d89f4ab',
        title: 'Intro',
        q: { type: 'audio' },
        a: { type: 'choice' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: '5358f983-6a3a-453a-9390-dd8264c14e85',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' }
      },
      {
        id: 'c92f0cb4-68ed-4988-8287-2b7cc152a8f1',
        title: 'Write',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      {
        id: 'c5e853b2-637d-46fd-a077-353af2414b0a',
        title: 'Read',
        q: { type: 'text' },
        a: { type: 'choice' },
      }
    ]
  },
  {
    id: 'dd4a4b6b-1801-4b22-bac1-e723bc7616ee',
    min: 30,
    max: 100,
    stepSize: 10,
    exercises: [
      {
        id: '501199c0-5765-433f-89af-f144f63134f2',
        title: 'Intro',
        q: { type: 'audio' },
        a: { type: 'choice' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: '56d71bee-3b55-404a-a01d-a34232bf5265',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' }
      },
      {
        id: '8afe163c-23c9-4d41-925f-873b8a60e99c',
        title: 'Write',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      {
        id: '006f1f86-6dab-43a2-afc6-faf95514db69',
        title: 'Read',
        q: { type: 'text' },
        a: { type: 'choice' },
      }
    ]
  },
  {
    id: '8c431de9-ff10-4379-857b-ed1316ce2f91',
    min: 100,
    max: 1000,
    stepSize: 100,
    exercises: [
      {
        id: '1dae1cef-d88a-4668-a416-5636d1d6e7e7',
        title: 'Intro',
        q: { type: 'audio' },
        a: { type: 'choice' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: '335e81b4-5c65-4f23-91e9-89ce8d5ddad1',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' }
      },
      {
        id: 'b0ab7b41-b5aa-4a87-bd5c-70296f9754f3',
        title: 'Write',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      {
        id: '8e90c1d6-476a-496f-900c-1f88d76aa7ca',
        title: 'Read',
        q: { type: 'text' },
        a: { type: 'choice' },
      }
    ]
  },
  {
    id: 'd4d0095a-6937-4310-b6da-7d8cf5c52058',
    min: 1000,
    max: 10000,
    stepSize: 1000,
    exercises: [
      {
        id: 'a6be5bd3-e4a3-42d2-9837-0972f93efd7f',
        title: 'Intro',
        q: { type: 'audio' },
        a: { type: 'choice' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: '2bdc19b6-c467-4d2d-8c09-8332d8fa0288',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' }
      },
      {
        id: '04f2dc04-c211-4d91-a7f4-120955873b2b',
        title: 'Write',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      //{
      //  id: 'd0f37fcb-fffe-4554-b996-b9f9ada1d301',
      //  title: 'Read',
      //  q: { type: 'text' },
      //  a: { type: 'choice' },
      //}
    ]
  },
];

const numberWords = {
  '01': 'ein',
  0: 'null',
  1: 'eins',
  2: 'zwei',
  3: 'drei',
  4: 'vier',
  5: 'fünf',
  6: 'sechs',
  7: 'sieben',
  8: 'acht',
  9: 'neun',
  10: 'zehn',
  11: 'elf',
  12: 'zwölf',
  13: 'dreizehn',
  14: 'vierzehn',
  15: 'fünfzehn',
  16: 'sechzehn',
  17: 'siebzehn',
  18: 'achtzehn',
  19: 'neunzehn',
  20: 'zwanzig',
  30: 'dreißig',
  40: 'vierzig',
  50: 'fünfzig',
  60: 'sechzig',
  70: 'siebzig',
  80: 'achtzig',
  90: 'neunzig',
  100: 'einhundert',
  200: 'zweihundert',
  300: 'dreihundert',
  400: 'vierhundert',
  500: 'fünfhundert',
  600: 'sechshundert',
  700: 'siebenhundert',
  800: 'achthundert',
  900: 'neunhundert',
  1000: 'eintausend'
};

/* Generates the written equivalent of a number in digits
 * @param number Number the number to be converted into a word
 * @param and String localized word "and" for the respective language
 * returns String word equivialent to the passed number
 */
function getNumberWord(number) {
  return generateNumberWord(numberWords, number, 'und');
}

export {
  meta,
  lessons,
  getNumberWord
}

