import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import './QuestionAudio.scss';
import playSolid from '../assets/icons/play-solid.svg';
import stopSolid from '../assets/icons/stop-solid.svg';

function QuestionAudio(props) {

	const [isPlaying, setIsPlaying] = useState(false);
  const { language } = useParams();
	let audio = React.createRef();

	function onClick() {
		audio.current.play();
	}

  function onError(evt) {
    alert('Audio cannot be played. Try to reload. If this error persists, please report it to services@tobi-or-not.de');
  }

	function onPlay() {
		setIsPlaying(true);
	}

	function onEnded() {
		setIsPlaying(false);
	}

	return (
		<div className="question-audio">
			<button onClick={onClick}>
				{isPlaying &&
				<img src={stopSolid} className="icon" alt="stop icon" /> }
				{!isPlaying &&
				<img src={playSolid} className="icon play" alt="play icon" /> }
			</button>
			<audio
        src={`/audio/${language}/${props.question}.mp3`}
				onPlay={onPlay}
				onEnded={onEnded}
        onError={onError}
				autoPlay
				ref={audio}>
		</audio>
		</div>
	)
}

export default QuestionAudio;
