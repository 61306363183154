import React from 'react';
import { useParams } from 'react-router-dom';

import './Dashboard.scss';
import Lesson from './Lesson';

import useLessonData from '../lessons/hooks.js';
import { v1 as uuidv1 } from 'uuid';

function Dashboard() {
  const { language } = useParams();
  const lessonData = useLessonData(language);
  const lessons = lessonData.lessons;
  const meta = lessonData.meta;

  function saveScrollPosition() {
    sessionStorage.setItem('scrollPositionDashboard', window.pageYOffset);
  }

  return(
    <div className="dashboard">
      <h1>{meta.title}</h1>
      { lessons.map((l, i) => {
        return <Lesson
          lesson={l}
          lessonId={i}
          saveScrollPosition={saveScrollPosition}
          key={uuidv1()}
        />
      })
      }
    </div>
  )
}

export default Dashboard;
