import shuffle from '../shared/shuffle';
import randomNumber from '../randomNumber';

function useGenerateQuestions(lesson, questionOrder = 'random') {
  let questions = [];
  const step = lesson.stepSize || 1;

  for(let i=lesson.min; i <= lesson.max; i+=step) {
    questions.push(i);
  }

  if(questionOrder === 'linear') {
    return questions;
  } else if(questionOrder === 'random') {
    return shuffle(questions);
  } else {
    throw new Error('InvalidQuestionOrderOption');
  }
}


function generateAnswerOptions(currentNumber, min, max, count, stepSize = 1) {
  let choices = [currentNumber];
  let i = 1;

  while(i < count) {
    let choice = randomNumber(min, max);
    if(!choices.includes(choice) && (choice % stepSize) === 0) {
      choices.push(choice);
      i++;
    }
  }
  return shuffle(choices);
}

export {
  useGenerateQuestions,
  generateAnswerOptions
};
