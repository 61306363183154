import React, { useState } from 'react';
import useFormattedNumber from '../shared/useFormattedNumber';
import './Choice.scss';
import smileRegular from '../assets/icons/smile-regular.svg';
import frownRegular from '../assets/icons/frown-regular.svg';

function Choice(props) {

	const [resultClass, setResultClass] = useState();
  const formattedNumber = useFormattedNumber();

	function handleClick(e) {
		const value = parseInt(e.target.value);
		if(value !== props.correctAnswer) {
			setResultClass('error');
		} else {
			setResultClass('correct');
		}
		props.handleClick(value);
	}

	function handleMouseUp(e) {
		e.target.blur();
	}

	return (
		<button
			className={['primary choice', resultClass].join(' ')}
			value={props.choice}
			onClick={handleClick}
			onMouseUp={handleMouseUp}
		>
				{formattedNumber(props.choice, props.numberSystem)}
		<img className="icon wrong" src={frownRegular} alt="wrong answer icon" />
		<img className="icon right" src={smileRegular} alt="right answer icon" />
	</button>
	)
}

export default Choice;
