import localStorageDB from 'localstoragedb';

export default function useDb() {
  var db = new localStorageDB('numbers', localStorage);

  if(db.isNew()) {
    db.createTable('progress',
      [
        'exerciseId',
        'correctAnswers',
        'wrongAnswers',
        'lastCompleted'
      ]
    );
    db.commit();
  }

  return db;
}


