function fillerZeros(n) {
  let result = '';
  for(let i = 1; i <= n; i++) {
    result += '0';
  }
  return result;
}

function buildTensPlace(numberWords, numberSection, onesPlace, and) {
  let wordArray = []
  let tensPlace = numberSection;
  const tensPlaceIsZero = parseInt(tensPlace) === 0;

  if(onesPlace && tensPlaceIsZero) {
    wordArray.unshift(numberWords[onesPlace])
  }
  else if(onesPlace) { // build number ones pl + tens pl
    wordArray.unshift(numberWords[tensPlace]);
    wordArray.unshift(and)
    wordArray.unshift(numberWords[onesPlace])
  }
  else {
    wordArray.unshift(numberWords[tensPlace]);
  }

  return wordArray;
}

function buildNumberWord(numberWords, number, and) {
  const numberString = number.toString();
  let wordArray = []
  let onesPlace;

  numberString.split('').reverse().forEach((digit, i) => {
    const numberSection = digit + fillerZeros(i);
    if(i === 0) { // ones Place
      // handle special case 'ein' vs 'eins'
      if(numberSection === '1') { onesPlace = '01'; }
      // zeros will be handled as part of the tens places
      else if(parseInt(numberSection) > 0) { onesPlace = numberSection; }

    } else if(i === 1) { // tens place
      wordArray = buildTensPlace(numberWords, numberSection, onesPlace, and);
    } else {
      wordArray.unshift(numberWords[numberSection]);
    }
  });

  return wordArray.filter((w) => { return w !== undefined }).join('');
}

/** generates a word for a number passed in digits
 * @param numberWords Object list of number words mapped to their digit equivilance
 * @param number Number number to be converted into a word
 * @param and String the word "and" localized for the respective language
 */
function generateNumberWord(numberWords, number, and) {
  let numberWord;

  if(number <= 20) {
    numberWord = numberWords[number]
  } else {
    numberWord = buildNumberWord(numberWords, number, and);
  }

  return numberWord;
}

export default generateNumberWord;
