import React from 'react';
import './QuestionText.scss';

function QuestionText(props) {

  return (
    <div className="question-text">
        { props.question }
    </div>
  )

}

export default QuestionText;
