import React from 'react';
import Choice from './Choice';
import './AnswerChoice.scss';
import {generateAnswerOptions} from './hooks';

import { v1 as uuidv1 } from 'uuid';

function AnswerChoice(props) {
  let attemptedAnswers = 0;

  function checkAnswer(answer) {
    attemptedAnswers += 1;

    if(answer === props.question) {
      const firstAnswerCorrect = attemptedAnswers === 1 ? true : false;
      setTimeout(() => props.handleCorrectAnswer(firstAnswerCorrect), 400);
    }
  }

  function buildChoices(question, min, max, choiceCount, numberSystem, stepSize) {
    return generateAnswerOptions(question, min, max, choiceCount, stepSize).map(choice => {
      return <li key={ uuidv1() }>
        <Choice
          choice={choice}
          correctAnswer={props.question}
          handleClick={checkAnswer}
          numberSystem={numberSystem}
        />
        </li>
    });
  }

  return (
    <ul className="answer-choice">
        { buildChoices(props.question, props.min, props.max, props.choiceCount, props.numberSystem, props.stepSize) }
    </ul>
  )
}

export default AnswerChoice;
