import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useDb from '../shared/useDb';

import './Completion.scss';
import PieChart from '../components/PieChart';

function Completion() {

  const { language , exerciseId} = useParams();
  const history = useHistory();

  let db = useDb();
  const res = db.queryAll('progress', { query: { exerciseId: exerciseId } })[0];

  let percentage = res.correctAnswers * 100 / (res.correctAnswers + res.wrongAnswers);

  function handleClose() {
    history.push(`/${language}`);
  }

  return (
    <div className="completion">
      <h1>Well done!</h1>
      <div className="progress">
      <PieChart
        percentage={percentage}
        foregroundColor="#127b63"
        backgroundColor="#9c7ebd"
        borderColor="none"
        animate={true}
      />
      </div>
      <button className="button primary" onClick={handleClose}>
        Back to Dashboard
      </button>
    </div>
  )

}

export default Completion;
