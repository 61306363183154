import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Quiz from './quiz/Quiz';
import Dashboard from './dashboard/Dashboard';
import Completion from './quiz/Completion';
import './App.scss';

import de from './assets/flags/de.svg';
import eg from './assets/flags/eg.svg';

function App() {

  return (
    <Router>
      <div className="wrapper">
        <Switch>
          <Route path="/:language/completion/:exerciseId">
            <Completion />
          </Route>

          <Route path="/:language/lesson/:lessonId/exercise/:exerciseId">
            <Quiz />
          </Route>

          <Route path="/:language">
            <Dashboard />
          </Route>

          <Route path="/">
            <Link to="/arabic">
              <img src={eg} alt="Egyptian flag" />
            </Link>
            <br /><br />
            <Link to="/german">
              <img src={de} alt="German flag" />
            </Link>
          </Route>

        </Switch>
      </div>
    </Router>
  )
}

export default App;
