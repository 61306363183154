import React, { useState } from 'react';
import './AnswerNumber.scss';
import { v1 as uuidv1 } from 'uuid';

function AnswerNumber(props) {
	const [answer, setAnswer] = useState();
	const [wrongAnswers, setWrongAnswers] = useState([]);

	function onSubmit(evt) {
		evt.preventDefault();
		evt.target.elements[0].value = ''
		evt.target.elements[0].focus();

		if(answer === props.question) {
      const firstAnswerCorrect = wrongAnswers.length === 0 ? true : false;
			props.handleCorrectAnswer(firstAnswerCorrect);
			setWrongAnswers([]);
		} else {
			let newWrongAnswers = wrongAnswers.slice(0);
			newWrongAnswers.push(answer);
			setWrongAnswers(newWrongAnswers);
		}
	}

	function onChange(evt) {
		setAnswer(parseInt(evt.target.value));
	}

	function buildWrongAnswers(answers) {
		return answers.map(answer => {
			return ( <li key={ uuidv1() }>{ answer }</li> )
		});
	}

	return (
		<div className="answer-number">
			<ul className="wrong-answers">
				{ buildWrongAnswers(wrongAnswers) }
			</ul>
			<form onSubmit={onSubmit}>
				<input type="number" pattern="[0-9]*" onChange={onChange} /><br/>
				<button className="primary">Submit</button>
			</form>
		</div>
	)
}

export default AnswerNumber;
