import React, { useState, useEffect } from 'react';


import './PieChart.scss';

function PieChart(props) {

  const duration = 1000;
  const frameRate = 10;
  const frameDuration = duration * 0.001 * frameRate;
  const animatedFramesCount = duration / frameRate;
  const percentageIncrement = props.percentage / animatedFramesCount;
  const animate = props.animate ? true : false;

  const [percentage, setPercentage] = useState(animate ? 0 : props.percentage - 0.2);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    if(animate && (elapsedTime <= duration)) {
      const intervalId = setInterval(() => {
        setPercentage(prev => {
          const newPercentage = prev += percentageIncrement;
          return newPercentage >= 100 ? 99.8 : newPercentage;
        });

        setElapsedTime(prev => { return prev += frameDuration});
      }, frameDuration);
      return () => clearInterval(intervalId);
    }
  });

  // loaned from https://stackoverflow.com/questions/21205652/how-to-draw-a-circle-sector-in-css
  function angle(percentage) {
    const deg = 360 * percentage * 0.01;

    if(deg <=180) {
      return 90 + deg;
    } else {
      return deg - 90;
    }
  }

  function color(percentage) {
    if(percentage <= 50) {
      return props.backgroundColor;
    } else {
      return props.foregroundColor
    }
  }

  const styles= {
    backgroundColor: props.foregroundColor,
    border:`2px solid ${props.borderColor}`,
    // sector
    backgroundImage: `
       linear-gradient(${angle(percentage)}deg, transparent 50%, ${color(percentage)} 50%),
       linear-gradient(90deg, ${props.backgroundColor} 50%, transparent 50%)
    `,
  };

  return(
    <div className="pie-chart" style={styles}></div>
  );
}

export default PieChart;
