import React from 'react';
import useFormattedNumber from '../shared/useFormattedNumber';
import './QuestionNumber.scss';

function QuestionNumber(props) {

  const formattedNumber = useFormattedNumber();

	return (
		<div className="question-number">
			{ formattedNumber(props.question, props.numberSystem) }
		</div>
	)
}

export default QuestionNumber;
