import { React ,useEffect } from 'react';
import { useParams, useHistory} from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';
import useDb from '../shared/useDb';

import './Lesson.scss';

import PieChart from '../components/PieChart';

function Lesson(props) {
  const l = props.lesson;
  const {language} = useParams();
  const lessonId = props.lessonId;

  const history = useHistory();
  const db = useDb();

  useEffect(() => {
    window.scrollTo(0, sessionStorage.getItem('scrollPositionDashboard'));
  });

  function handleClick(evt) {
    props.saveScrollPosition();
    const exerciseId = evt.currentTarget.value;
    history.push(`/${language}/lesson/${lessonId}/exercise/${exerciseId}`);
  }

  function getProgress(exerciseId) {
    const res = db.queryAll('progress', { query: { exerciseId: exerciseId } })[0];
    if(res) {
      const total = res.correctAnswers + res.wrongAnswers;
      const percentage = res.correctAnswers / total * 100;
      return percentage;
    } else {
      return 0;
    }
  };

  function buildList(exercises) {
    return exercises.map((e, i) => {
      const progress = getProgress(e.id);

      return 	(
        <li key={uuidv1()}>
          <button value={i} onClick={handleClick} className="primary tile">
            {true &&
            <div className="progress">
              <PieChart
                percentage={progress}
                foregroundColor="#127b63"
                backgroundColor="#9c7ebd"
                borderColor="#9376b3"
              />
            </div>
            }
            <span className="body">{e.title}</span>
          </button>
        </li>
      )
    })
  }


  return (
    <section className="lesson">
      <h2>{`${l.min} - ${l.max}`}</h2>
      <ul className="grid-container">{buildList(l.exercises)}</ul>
    </section>
  )
}

export default Lesson;
