import generateNumberWord from '../shared/numberWords';

// question types
// - number
// - audio
// - combi (text + audio)
// - [text]

// answer types
// - choice
// - number


const meta = {
  title: 'Learn Egyptian Arabic Numbers'
};

const lessons = [
  {
    id: '9e769435-cc94-4ba7-a506-a7d4b5d1eba2',
    min: 0,
    max: 9,
    exercises: [
      {
        id: '6ba89021-96f3-4e04-955c-52c95f996ed9',
        title: 'Intro',
        q: { type: 'combi' },
        a: { type: 'choice', numberSystem: 'indian' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: '55191481-3811-469a-97a7-aade2814886e',
        title: 'Read digits',
        q: { type: 'number', numberSystem: 'indian' },
        a: { type: 'choice' },
      },
      {
        id: 'ebd3b9ab-fbcf-494c-8ab0-d77381aa07c9',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' },

      },
      {
        id: '5f21e7e7-39a1-4257-b889-4918cf1e83fe',
        title: 'Write digits',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      {
        id: '0ed01b4e-ef79-4b57-a250-3d0c46a8088d',
        title: 'Read words',
        q: { type: 'text' },
        a: { type: 'choice' },
      }
    ]
  },
  {
    id: '850f578d-d7cb-45f2-8b8d-a29f44166a2f',
    min: 10,
    max: 20,
    exercises: [
      {
        id: '428035af-228d-4904-b67e-efdd5af714ae',
        title: 'Intro',
        q: { type: 'combi' },
        a: { type: 'choice', numberSystem: 'indian' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: 'acdbf097-2a80-405f-bfe2-f632d133a859',
        title: 'Read digits',
        q: { type: 'number', numberSystem: 'indian' },
        a: { type: 'choice' }
      },
      {
        id: '76411eab-7e15-49ce-86db-c5f6a2dd4ce1',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' }
      },
      {
        id: 'e1212ef1-5f0f-4722-a8e7-a5b6f6c538f5',
        title: 'Write',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      {
        id: '1a4900c2-0d6c-4fba-8a5b-4bd3192a777a',
        title: 'Read words',
        q: { type: 'text' },
        a: { type: 'choice' },
      }
    ]
  },
  {
    id: '36e05c32-622a-4099-88a6-86a8b91711e4',
    min: 20,
    max: 29,
    exercises: [
      {
        id: 'ee3813e4-2953-4d7f-aadd-3df70edc9c4e',
        title: 'Intro',
        q: { type: 'combi' },
        a: { type: 'choice', numberSystem: 'indian' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: '0bf7446a-5d4c-44da-b7a8-287916a5f4ca',
        title: 'Read digits',
        q: { type: 'number', numberSystem: 'indian' },
        a: { type: 'choice' }
      },
      {
        id: '28b600e5-5e6f-4a45-8da5-2e8189f1b545',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' }
      },
      {
        id: '627aa050-2a50-499e-b196-dd1850785347',
        title: 'Write',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      {
        id: '195cbde1-47c4-4a27-96b3-5b330d403d93',
        title: 'Read words',
        q: { type: 'text' },
        a: { type: 'choice' },
      }
    ]
  },
  {
    id: '179a974a-9c3f-4e35-b2bb-0eb44d3cb2f5',
    min: 30,
    max: 100,
    stepSize: 10,
    exercises: [
      {
        id: '3d42c5ba-e690-46ec-84b7-de4daba80882',
        title: 'Intro',
        q: { type: 'combi' },
        a: { type: 'choice', numberSystem: 'indian' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: 'b1133a43-61d3-41a7-b74d-c24734a3be4c',
        title: 'Read digits',
        q: { type: 'number', numberSystem: 'indian' },
        a: { type: 'choice' }
      },
      {
        id: '862d0a95-a03d-4f1a-97d8-444c950585f8',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' }
      },
      {
        id: 'e8b1d8b5-6acf-4bc0-994b-3877fd1fdf85',
        title: 'Write',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      {
        id: 'fb91a3da-dfe4-4c66-982a-bc03ac075806',
        title: 'Read words',
        q: { type: 'text' },
        a: { type: 'choice' },
      }
    ]
  },
  {
    id: '43fef375-ff9b-4899-8ce5-b857bd850cec',
    min: 100,
    max: 1000,
    stepSize: 100,
    exercises: [
      {
        id: 'c6f7a373-3741-43d6-907e-7af7e6be6c9b',
        title: 'Intro',
        q: { type: 'combi' },
        a: { type: 'choice', numberSystem: 'indian' },
        choiceCount: 2,
        questionOrder: 'linear'
      },
      {
        id: 'fe033fe0-4a7a-4b0a-9719-f55673387447',
        title: 'Read digits',
        q: { type: 'number', numberSystem: 'indian' },
        a: { type: 'choice' }
      },
      {
        id: '379e23f7-181e-4c44-b4dc-a7cdce4d75d6',
        title: 'Listen',
        q: { type: 'audio' },
        a: { type: 'choice' }
      },
      {
        id: 'bda3b29f-16f5-4854-86f2-457fe31360da',
        title: 'Write',
        q: { type: 'audio' },
        a: { type: 'number' }
      },
      {
        id: 'ff7ff54e-dc60-4d9b-b8a8-857954c0df10',
        title: 'Read words',
        q: { type: 'text' },
        a: { type: 'choice' },
      }
    ]
  }
];

const numberWords = {
  '01': 'واحد',
  0: 'صـِفر',
  1: 'واحد',
  2: 'اثنين',
  3: 'ثلاثة',
  4: 'اربعة',
  5: 'خمسة',
  6: 'ستة',
  7: 'سبعة',
  8: 'ثمانية',
  9: 'تسعة',
  10: 'عشرة',
  11: 'حداشر',
  12: 'اتناشر',
  13: 'تلاتاشر',
  14: 'اربعااشر',
  15: 'خمستاشر',
  16: 'ستاشر',
  17: 'سبعتاشر',
  18: 'تمنتاشر',
  19: 'تسعتاشر',
  20: 'عشرين',
  30: 'ثلاثون',
  40: 'اربعون',
  50: 'خمسون',
  60: 'ستون',
  70: 'سبعون',
  80: 'ثمانون',
  90: 'تسعون',
  100: 'مية',
  200: 'متين',
  300: 'تلتمية',
  400: 'ربعمية',
  500: 'خمسمية',
  600: 'ستمية',
  700: 'سبعمية',
  800: 'تمنمية',
  900: 'تسعمية',
  1000: 'الف'
}

/* Generates the written equivalent of a number in digits
 * @param number Number the number to be converted into a word
 * @param and String localized word "and" for the respective language
 * returns String word equivialent to the passed number
 */
function getNumberWord(number) {
  return generateNumberWord(numberWords, number, ' و');
}

export {
  meta,
  lessons,
  getNumberWord
}
